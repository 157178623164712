
<div>
  <v-divider></v-divider>
  <v-row v-if="installationOptionsHtml" class="pt-3 pb-3 installation-option">
    <div v-html="installationOptionsHtml"></div>
  </v-row>
  <div v-if="creditApprovedSchedulingText" class="pt-3 pb-3">
    <span v-html="creditApprovedSchedulingText"></span>
  </div>
  <div v-if="automatedSchedulingMessage && integrationSchedule && integrationSchedule.automated === 'yes'" class="pt-3 pb-3">
    <span v-html="automatedSchedulingMessage"></span>
  </div>
  <div v-if="allowScheduling">
    <v-row v-if="integrationSchedule && integrationSchedule.automated === 'no'">
      <v-col cols="12" class="options">
        <NonAutomatedSchedulingOptions />
      </v-col>
    </v-row>
    <div v-if="showCalendars">
      <v-row>
        <v-col cols="12" sm="7" class="my-2 pl-sm-12 px-6">
          <v-row justify="center" id="date-picker">
            <v-date-picker
              v-model="schedule.scheduleDate"
              :header-color="schedulingHeaderColor"
              :allowed-dates="dateAllowed"
              :min="getStartDate"
              :max="getEndDate"
              width="100%"
              @change="dateSelected"
            >
            </v-date-picker>
          </v-row>
        </v-col>
        <v-col cols="12" sm="5">
          <v-container fluid>
            <v-row no-gutters v-if="showSecondaryCalendar">
              <v-col class="preference" v-html="firstCalendarText"></v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-radio-group v-model="schedule.timeSlots[0]" :mandatory="true" @change="timeSlotChanged">
                  <v-radio v-for="t in optionsForDay" :key="t.getStartTime" class="timeRadio" :label="t.label" :value="t">
                    <template v-if="t.labelMacro" v-slot:label>
                      <span v-html="uiMacroParser(shopper, t.labelMacro, true)"></span> </template
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row v-if="showSecondaryCalendar">
        <v-col cols="12" sm="7" class="my-2 pl-sm-12 px-6">
          <v-row justify="center" id="date-picker">
            <v-date-picker
              v-model="schedule.scheduleDate2"
              :header-color="schedulingHeaderColor"
              :allowed-dates="dateAllowed"
              :min="getStartDate"
              :max="getEndDate"
              width="100%"
              @change="dateSelected2"
            ></v-date-picker>
          </v-row>
        </v-col>
        <v-col cols="12" sm="5">
          <v-container fluid>
            <v-row no-gutters>
              <v-col v-html="secondCalendarText" class="preference"></v-col>
            </v-row>
            <v-row no-gutters>
              <v-radio-group v-model="schedule.timeSlots[1]" :mandatory="true" @change="timeSlotChanged">
                <v-radio v-for="t in optionsForDay2" :key="t.getStartTime" class="timeRadio" :label="t.label" :value="t"
                  ><template v-if="t.labelMacro" v-slot:label>
                    <span v-html="uiMacroParser(shopper, t.labelMacro, true)"></span> </template
                ></v-radio>
              </v-radio-group>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </div>
  </div>
</div>
