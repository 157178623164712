
<div>
  <StepperButtons :forwardAction="openModal" :buttonText="paymentModalButtonText" key="PaymentStepper" :disabled="disabled" />
  <div v-if="showModal && (iFrameSrcDoc || iFrameSrc)">
    <v-overlay :opacity="0.5" v-if="showModal" z-index="6" class="customOverlay">
      <v-dialog v-model="showModal" @close="showModal = false" width="500px">
        <v-card class="dialog-card">
          <PaymentSuccess v-if="buttonState === 'success'" />
          <div v-else>
            <div class="close-button">
              <v-btn icon @click="showModal = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <div class="pay-header" v-if="paymentConfig.payHeaderHtml || paymentConfig.headerLogo">
              <v-img contain :src="paymentConfig.headerLogo" height="80" max-width="200px"></v-img>
              <div v-html="paymentConfig.payHeaderHtml" class="mb-3"></div>
            </div>
            <div class="pay-content">
              <p
                v-if="paymentConfig.aboveIframeHtml"
                class="mb-3 font-weight-normal"
                v-html="insertPrices(paymentConfig.aboveIframeHtml)"
              ></p>

              <div class="iframe-wrapper">
                <iframe v-if="iFrameSrc" id="paymentIframe" class="gocare-iframe" :src="iFrameSrc" />
                <iframe v-else-if="iFrameSrcDoc" id="paymentIframe" class="gocare-iframe" :srcdoc="iFrameSrcDoc" />
                <div v-else>This should never happen, please contact your administration</div>
              </div>
              <div v-if="errorsArr.length" class="error-container">
                <span v-for="err in errorsArr" v-html="err" />
              </div>
              <div class="pay-container">
                <v-btn class="pay-button" dark :color="submitColor" @click="submitPayment">{{ payBtnTxt }}</v-btn>
              </div>
              <div class="skip-container" v-if="showSkipPayment">
                <v-btn class="skip-button" text @click="skipPayment">{{ skipPaymentText }}</v-btn>
              </div>

              <div v-if="paymentConfig.disclaimer" class="disclaimer">
                <p v-html="paymentConfig.disclaimer" />
              </div>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </v-overlay>
  </div>
</div>
