import { LegacyAutomationState } from '@adg/catalog/src/common/AutomationState'
import { CreditCheckResponse, CreditCheckType } from '@adg/catalog/src/common/CreditChecks'
import { usePiniaShopper } from '@/store/pinia/piniaShopper'
import { usePiniaUIConfig } from '@/store/pinia/piniaUIConfig'
import useCart from '@/components/order/cart/useCart'
import useShopper from '@/components/order/useShopper'
import { GET_MATCHED_ADDRESS } from '@/store/types'
import { ConfigKeys, OrderSchedule } from '@adg/catalog/src/modules/Catalog'
import { IShopper } from '@adg/catalog/src/modules/Shopper'
import { computed, ComputedRef } from '@vue/composition-api'
import { getConfigBoolean, getConfigItem, getConfigString } from '../getConfigItem'
import { bus } from '@/main'
import { logger } from '@/utils/RemoteLogger'

import { MatchType } from '@adg/catalog/src/common/MatchType'
import useAutomationState from '@/store/useAutomationState'
import { getFirstUrlParam, UrlParams } from '@adg/catalog/src/common/utils'
import $store from '@/store'
import { ApiConfig } from '@adg/catalog/src/common/UIConfig'
import { Spinner } from '@/store/State'

export default () => {
  const isStudent = computed(() => $store.getters.getStudent)
  const matchType = computed(() => $store.getters[GET_MATCHED_ADDRESS]?.matchType)
  const useConverge = computed(() => getConfigBoolean(ConfigKeys.useConverge))
  const shopper: ComputedRef<IShopper> = computed(() => $store.getters.getShopper)
  const schedule = computed((): OrderSchedule => $store.getters.getAvailableSchedule)
  const hasBadCredit = computed(() => {
    return shopper.value.customInfo?.creditCheckPassed !== true
  })
  const hasGoodCredit = computed(() => {
    return shopper.value.customInfo?.creditCheckPassed
  })
  const mustPrePay = computed(() => {
    const autoState = shopper.value.automationState
    if (schedule.value?.automated !== 'yes') return false
    return autoState?.status === 'success' && useConverge.value && (hasBadCredit.value || isStudent.value)
    // return useConverge.value && (hasBadCredit.value || isStudent.value)
  })

  const mustPrePayGoCare = computed(() => {
    //debug logic
    const urlParamsObj: UrlParams = $store.getters.getUrlParams
    const isDebugMode: boolean = $store.getters.getDebugMode
    const mustPayField = getFirstUrlParam(urlParamsObj.pay)
    if (isDebugMode && mustPayField) {
      if (mustPayField.match(/t|y|true|yes/i)) return true
      if (mustPayField.match(/f|n|false|no/i)) return false
      //else use normal logic
    }

    if (matchType.value !== MatchType.EXACT) return false
    if (shopper.value.cancelAutomation) return false
    if (schedule.value?.automated !== 'yes') return false

    //normal logic
    const autoState = shopper.value.automationState
    return autoState?.status === 'success' && hasBadCredit.value
  })

  const hasPerformedCreditCheck = computed((): boolean => {
    return (shopper.value.customInfo?.creditChecks ?? []).length > 0
  })

  const downPayment = computed((): boolean => {
    const { downPaymentTotal } = useCart($store)
    return downPaymentTotal.value > 0
  })

  const recurringPayment = computed((): boolean => {
    const { recurringTotal } = useCart($store)

    return recurringTotal.value > 0
  })

  const mustTakePayment = computed((): boolean => {
    const { recurringTotal, downPaymentTotal } = useCart($store)
    const takeZeroDollarPayment = getConfigItem(ConfigKeys.paymentConfig)?.takeZeroDollarPayment ?? false // take zero dollar payment
    return recurringTotal.value > 0 || downPaymentTotal.value > 0 || takeZeroDollarPayment
  })

  const isInitialPreSale = computed((): boolean => {
    const shopper = $store.getters.getShopper as IShopper
    return !!shopper.customInfo.preSale && !shopper.customInfo.returningPreSale
  })

  // const softCreditCheck = (onComplete) => {
  //   const softCheck: CreditCheckType = 'soft'
  //   const { phone } = useCart($store)
  //   $store.commit('addSpinner', '')
  //   $store
  //     .dispatch('creditCheck', softCheck)
  //     .then((data) => {
  //       if (data.status === 'failure') {
  //         bus.$emit('stepForward')
  //       } else {
  //         const isNewPhone: boolean = phone.value?.upgrades.find((u) => u.Name === 'New') !== undefined
  //         const newPhoneNum: string = data?.availablePhoneNumbers?.phoneNumbers?.[0]

  //         if (isNewPhone && newPhoneNum) {
  //           $store.commit('updatePhoneInfoValid', true)
  //           $store.commit('updatePhoneInfo', newPhoneNum)
  //         }
  //         if (data?.externalAccountNumber) {
  //           $store.commit('setExternalAccountNumber', data.externalAccountNumber)
  //           usePiniaShopper().shopper.externalAccountNumber = data.externalAccountNumber
  //         }
  //         $store.commit('addCreditCheck', data)
  //         updatePiniaCreditCheck(data)
  //         onComplete(data?.softCreditCheck?.approved)

  //         // if (data?.softCreditCheck?.approved) {
  //         //   onSuccess()
  //         // } else {
  //         //   onFailure()
  //         // }
  //       }
  //     })
  //     .catch((err) => {
  //       bus.$emit('goToError', err)
  //     })
  //     .finally(() => {
  //       $store.commit('removeSpinner', '')
  //     })
  // }

  const agnosticCreditCheck = (moveForward: () => void, openModal: () => void, latestCreditCheck: CreditCheckResponse) => {
    const { cancelAutomation } = useAutomationState($store)
    //call should credit check
    const { phone } = useCart($store)
    const disableIfCreditCheckFail = getConfigString(ConfigKeys.creditCheckTypeFailureDisablesAutomation)
    const disableIfCreditCheckNotPass = getConfigString(ConfigKeys.creditCheckTypeNotPassedDisablesAutomation)
    const creditTypeMatch = disableIfCreditCheckFail ? new RegExp(disableIfCreditCheckFail) : undefined
    const notPassedCreditTypeMatch = disableIfCreditCheckNotPass ? new RegExp(disableIfCreditCheckNotPass) : undefined
    const creditCheckApiConfig: ApiConfig | undefined = getConfigItem(ConfigKeys.creditCheckApiConfig)
    const creditCheckSpinner: Spinner = {
      id: 'creditCheck',
      messages: creditCheckApiConfig?.messages ?? [''],
      spinnerUpdateTime: creditCheckApiConfig?.messageTimeout ?? 10,
      rank: 1
    }
    $store.commit('addSpinner', creditCheckSpinner)
    $store
      .dispatch('creditCheck', latestCreditCheck.next)
      .then((data: LegacyAutomationState) => {
        if (data.status === 'failure') {
          if (creditTypeMatch && data.softCreditCheck?.creditCheckType.match(creditTypeMatch)) {
            cancelAutomation('Credit Check Automation State Failed')
          }
          $store.commit('removeSpinner', 'creditCheck')
          moveForward()
        } else {
          if (data.softCreditCheck?.creditCheckResult === 'failed' || data.softCreditCheck?.creditCheckResult === 'disabled') {
            if (notPassedCreditTypeMatch && data.softCreditCheck?.creditCheckType.match(notPassedCreditTypeMatch)) {
              cancelAutomation('Credit Check Not Passed')
            }
          }
          const isNewPhone: boolean = phone.value?.upgrades.find((u) => u.Name === 'New') !== undefined
          const newPhoneNum: string | undefined = data?.availablePhoneNumbers?.phoneNumbers?.[0]

          if (isNewPhone && newPhoneNum) {
            $store.commit('updatePhoneInfoValid', true)
            $store.commit('updatePhoneInfo', newPhoneNum)
          }
          if (data?.customer?.accountNumber) {
            $store.commit('setExternalAccountNumber', data.customer.accountNumber)
            usePiniaShopper().shopper.externalAccountNumber = data.customer.accountNumber
          }
          $store.commit('addCreditCheck', data)
          updatePiniaCreditCheck(data)
          $store.commit('removeSpinner', 'creditCheck')
          if (data?.softCreditCheck?.next) {
            openModal(data?.softCreditCheck?.next)
          } else {
            moveForward()
          }
        }
      })
      .catch((err) => {
        logger.error('error calling agnostic credit check', err)
        if (creditTypeMatch && latestCreditCheck.next?.match(creditTypeMatch)) {
          cancelAutomation('Credit Check Automation State Failed')
        }
        $store.commit('removeSpinner', 'creditCheck')
        moveForward()
      })
  }

  const isExactMatch: ComputedRef<boolean> = computed(() => $store.getters[GET_MATCHED_ADDRESS]?.matchType === MatchType.EXACT)
  const { shouldAutomate } = useAutomationState($store)
  const shouldCreditCheck = (previousCreditCheck: CreditCheckResponse): boolean => {
    if (!previousCreditCheck.next) return false
    if (previousCreditCheck.conditions?.includes('shouldAutomate') && !shouldAutomate.value) return false
    if (previousCreditCheck.conditions?.includes('isExactMatch') && !isExactMatch.value) return false
    return true
  }

  const updatePiniaCreditCheck = (creditCheck) => {
    if (!usePiniaShopper().shopper.customInfo.creditChecks) {
      usePiniaShopper().shopper.customInfo.creditChecks = []
    }
    usePiniaShopper().shopper.customInfo.creditChecks.push(creditCheck)
    usePiniaShopper().shopper.customInfo.creditCheckPassed = creditCheck?.softCreditCheck?.creditCheckResult === 'approved'
  }
  return {
    //softCreditCheck,
    hasBadCredit,
    hasGoodCredit,
    hasPerformedCreditCheck,
    mustPrePay,
    mustPrePayGoCare,
    agnosticCreditCheck,
    shouldCreditCheck,
    downPayment,
    recurringPayment,
    mustTakePayment,
    isInitialPreSale
  }
}
