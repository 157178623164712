<template>
  <v-container style="max-width: 1200px" class="px-sm-12 px-2">
    <v-row class="pa-0" id="customize">
      <v-col cols="12">
        <SubHeader
          v-if="customizePageSubHeader"
          :icon="customizePageSubHeader.icon"
          :largeText="customizePageSubHeader.largeText"
          :smallText="customizePageSubHeader.smallText"
        ></SubHeader>
      </v-col>
      <v-col class="col-md-7 col-sm-12 px-0">
        <v-container fluid class="py-0">
          <div v-for="([productType, products], index) in Object.entries(sortedProducts)" :key="index">
            <component
              :is="products[0]['UI Component']"
              :product="
                products.length > 1 ? products.map((p) => ({ ...p, displayOrder: index })) : { ...products[0], displayOrder: index }
              "
              :sharpCorners="sharpCorners"
            />

            <!--          <div-->
            <!--            style="padding-bottom: 20px"-->
            <!--            v-for="(product, index) in sortedProducts"-->
            <!--            :key="product.Name">-->

            <!--          <component-->
            <!--              :is="product[UI_COMPONENT]"-->
            <!--              :product="{...product, displayOrder: index}">-->
            <!--            </component>-->
          </div>

          <div id="customize-streaming-services">
            <StreamingServices :sharpCorners="sharpCorners" />
          </div>

          <!--          This takes a Package or should it just take items interested in (Upgrades with subcategory Home Automation)-->
          <div>
            <HomeAutomation :sharpCorners="sharpCorners" />
          </div>

          <!--          This takes a Package or just for now Upgrade of type Package Service (Service Protection Plan-->
          <div v-if="!additionalServicesInsideInternetCard">
            <AdditionalServices :sharpCorners="sharpCorners" />
          </div>

          <StepperButtons />
        </v-container>
      </v-col>
      <v-col class="hidden-sm-and-down col-md-5 col-sm-12">
        <Cart />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from '@vue/composition-api'
import Cart from '@/components/order/cart/Cart.vue'
import Internet from '@/components/order/internet/Internet.vue'
import Tv from '@/components/order/tv/Tv.vue'
import Phone from '@/components/order/Phone.vue'
import { COMPONENT_ORDER, GET_CATALOG, SUBCATEGORY, UI_COMPONENT } from '@/store/types'
import { sortBy, groupBy } from 'lodash'
import AdditionalServices from '@/components/order/AdditionalServices.vue'
import StreamingServices from '@/components/order/StreamingServices.vue'
import useOrder from '@/components/order/useOrder'
import StepperButtons from '@/components/order/StepperButtons.vue'
import HomeAutomation from '@/components/order/home-automation/HomeAutomation.vue'
import $store from '@/store'
import { ConfigKeys, Product, Upgrade } from '@adg/catalog/src/modules/Catalog'
import { VContainer, VRow, VCol } from 'vuetify/lib'
import { getConfigItem } from '../shared/getConfigItem'
import SubHeader from '../layout/SubHeader.vue'

export default defineComponent({
  name: 'Customize',
  components: {
    Cart,
    Tv,
    Internet,
    Phone,
    AdditionalServices,
    StepperButtons,
    HomeAutomation,
    StreamingServices,
    SubHeader
  },
  setup(props, { emit, root }) {
    const { $router } = root as any
    const render = ref(0)
    const catalog = computed(() => $store.getters[GET_CATALOG])
    const refData = computed(() => catalog.value.RefData)
    const products = computed(() => $store.getters.getPackageProducts as Product[])
    const sharpCorners = computed(() => getConfigItem(ConfigKeys.cardSharpCorners) ?? false)
    const sortOrder = (p: Product) => p[COMPONENT_ORDER]
    const sortedProducts = computed(() => {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      render.value = render.value + 1
      const sorted = products.value ? sortBy(products.value, sortOrder) : []
      const grouped = groupBy(sorted, (p) => p['Product Type'])
      return grouped
    })
    const customizePageSubHeader = computed(() => getConfigItem(ConfigKeys.customizePageSubHeader) ?? undefined)
    const additionalServicesInsideInternetCard = computed(
      () => getConfigItem(ConfigKeys.additionalServicesInsideInternetCard) ?? false
    )

    const hasPkgUpgrades = computed(
      () =>
        $store.getters.getAvailablePackageUpgrades
          .filter((packageUpgrade: Upgrade) => packageUpgrade[SUBCATEGORY] === 'Package Service')
          .length() > 0
    )

    const { validation } = useOrder($store, $router)

    return {
      //      render,
      hasPkgUpgrades,
      refData,
      sortedProducts,
      UI_COMPONENT,
      validation,
      emit,
      sharpCorners,
      customizePageSubHeader,
      additionalServicesInsideInternetCard
    }
  }
})
</script>

<style>
.v-list-item__action {
  align-self: flex-start !important;
}

.v-list-item__content {
  align-self: flex-start !important;
}

.v-list-item__title {
  white-space: normal !important;
}

.radio-row {
  align-items: start;
}
</style>
