<template>
  <div class="sub-header-container">
    <span v-if="largeText" class="sub-header-large-text">
      <div>
        {{ largeText }}
        <v-btn>
          <v-icon v-if="icon && punctuatedAddress">{{ icon }}</v-icon
          >{{ punctuatedAddress }}</v-btn
        >
      </div>
    </span>
    <div v-if="smallText" class="subheader-small-text">{{ smallText }}</div>
  </div>
</template>
<script lang="ts">
import { getPunctuatedAddressFromTags } from '@adg/catalog/src/common/addressUtil'
import { computed, defineComponent } from '@vue/composition-api'
import $store from '@/store'
export default defineComponent({
  name: 'SubHeader',
  components: {},
  props: {
    largeText: {
      type: String,
      default: ''
    },
    smallText: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    }
  },
  setup(props, { refs, root }) {
    const punctuatedAddress = computed(() => getPunctuatedAddressFromTags($store.getters.getShopper))
    return { punctuatedAddress }
  }
})
</script>

<style></style>
