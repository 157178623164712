
<v-container class="pa-0">
  <v-row :class="$vuetify.breakpoint.xs ? ['pt-2', 'text-center'] : ''" dense v-if="packageUpgrades.length > 0">
    <v-col
      v-if="product['Product Type'] === 'Internet'"
      class="title primary--text internet-upgrade-header"
      id="customize-group-header"
      v-html="internetUpgradeText"
    ></v-col>
    <v-col
      v-if="product['Product Type'] === 'TV'"
      class="title primary--text tv-upgrade-header"
      id="customize-group-header"
      v-html="tvUpgradeText"
    ></v-col>
    <v-col
      v-if="product['Product Type'] === 'Phone'"
      class="title primary--text phone-upgrade-header"
      id="customize-group-header"
      v-html="phoneUpgradeText"
    ></v-col>
  </v-row>
  <v-row class="upgrade-row" v-for="pu in packageUpgrades" :key="pu.Package">
    <v-col cols="8">
      <v-list-item class="upgrade-list-item">
        <v-list-item-content class="pa-0">
          <v-list-item-title class="upgrade-display-name">
            {{ fullUpgradeText ? packageUpgradeText(pu) : pu.UpgradeText }}
            <Tooltip :payload="{ Name: pu.UpgradeText }" />
            <TooltipDebug :item="pu" :name="pu.Package" />
          </v-list-item-title>
          <v-list-item-subtitle v-if="pu.Subtitle" v-html="pu.Subtitle"></v-list-item-subtitle>
          <v-list-item-subtitle v-if="showPackageUpgradePriceDurationAndTerms"
            ><span
              ><div class="upgrade-price">{{ '$' + pu.monthlyPrice.toFixed(0) }}</div>
              <div class="upgrade-duration">/mo</div>
              <div class="upgrade-price-terms">Taxes & fees incl.</div>
            </span></v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
    </v-col>
    <v-col cols="4" class="text-right pt-2 upgrade">
      <div v-if="packageUpgradeButtonTitle" class="package-upgrade-button-title">{{ packageUpgradeButtonTitle }}</div>
      <v-btn
        v-if="!useAlternativeButton"
        :small="$vuetify.breakpoint.xs"
        @click="upgradePackage(pu.Package)"
        :color="buttonColor(upgradeButtonColor)"
        :class="buttonTextColor()"
        v-bind="btnAttrs"
        id="upgrade-button"
        >{{ getUpgradeButtonText(pu.Price) }}
        <v-icon class="icon" v-if="upgradeButtonIcon">{{ upgradeButtonIcon }}</v-icon></v-btn
      >
      <v-btn
        width="100%"
        rounded
        v-if="useAlternativeButton"
        :small="$vuetify.breakpoint.xs"
        @click="upgradePackage(pu.Package)"
        color="yellow"
        :class="buttonTextColor()"
        v-bind="btnAttrs"
        id="alt-upgrade-button"
        >Select</v-btn
      >
    </v-col>
  </v-row>
  <hr class="card-divider" v-if="dividersInCards && packageUpgrades.length > 0" />
</v-container>
