
<div>
  <StepperButtons
    :forwardAction="openModal"
    :buttonText="paymentModalButtonText"
    key="PaymentStepper"
    :thirdButtonText="thirdButtonText"
    :thirdButtonAction="thirdButtonAction"
    :disabled="disabled"
  />
  <div v-if="showModal && iFrameSrcDoc">
    <v-overlay :opacity="0.5" v-if="showModal" z-index="6" class="customOverlay">
      <v-dialog v-model="showModal" @close="showModal = false" width="400px">
        <v-card class="dialog-card">
          <PaymentSuccess v-if="buttonState === 'success'" />
          <div v-else>
            <div class="close-button">
              <v-btn icon @click="showModal = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <div class="pay-header">
              <v-img contain :src="headerLogo" height="80" max-width="200px"></v-img>
              <!-- <div class="mb-3">Vexus Secure Payment</div> -->
            </div>
            <v-form v-model="validated" class="pay-content">
              <p
                v-if="paymentConfig.aboveIframeHtml"
                class="mb-3 font-weight-normal"
                v-html="insertPrices(paymentConfig.aboveIframeHtml)"
              />

              <p v-else class="mb-3 font-weight-normal">
                One last step! Payment is required at this time to complete your Vexus Fiber order and to confirm your
                installation appointment. Please enter payment information below:
              </p>
              <div class="iframe-wrapper">
                <iframe id="gocareIframe" class="gocare-iframe" :srcdoc="iFrameSrcDoc" scrolling="yes" />
              </div>
              <div v-if="paymentConfig.payIconHigh" class="pay-methods">
                <span>Available Payment Methods:</span>
                <v-img src="/api/content/payment-methods.jpg" width="250" height="30" />
              </div>
              <v-list>
                <v-list-item>
                  <v-list-item-action style="align-self: start">
                    <v-checkbox v-model="showBillingFields" class="billing-checkbox" />
                  </v-list-item-action>
                  <v-list-item-content>
                    <span class="billing-different-text"
                      >My billing address is different from my service address<span v-if="serviceAddress">:</span></span
                    >
                    <span class="service-address">
                      {{ serviceAddress }}
                    </span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <div v-if="showBillingFields" class="pay-account-fields">
                <v-row v-if="paymentConfig.accountFields?.length">
                  <v-col
                    v-for="field of paymentConfig.accountFields"
                    :key="field.storeProp"
                    :class="field.class ? field.class : 'col-12 pt-0'"
                  >
                    <AccountFieldUI :field="field" @change="accountFieldChanged($event, field.changeEvent)" />
                  </v-col>
                </v-row>
              </div>

              <v-radio-group v-if="allowAutoPay" class="pay-radio-group" v-model="autoPay" row>
                <v-radio :value="false">
                  <template v-slot:label>
                    <span v-if="paymentConfig.payRadioText" v-html="insertPrices(paymentConfig.payRadioText)" />
                    <span v-else>Make a one-time payment of ${{ grandTotal.toFixed(2) }} today</span>
                  </template>
                </v-radio>
                <v-radio class="radio-top" :value="true">
                  <template v-slot:label>
                    <div v-if="paymentConfig.autoPayRadioText">
                      <span v-html="insertPrices(paymentConfig.autoPayRadioText)" />
                      <Tooltip :payload="{ Name: 'AutoPay' }" />
                    </div>
                    <div v-else>
                      <span> Make a one-time payment of ${{ grandTotal.toFixed(2) }} today AND enroll me in Vexus Easy Pay </span>
                      <Tooltip :payload="{ Name: 'AutoPay' }" />
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>

              <div class="disclaimer">
                <span v-if="paymentConfig.disclaimer" v-html="paymentConfig.disclaimer" />
                <span v-else
                  >By clicking below, you agree to the <a>privacy policy</a>, <a>refund policy</a>, and
                  <a>terms of service</a>
                </span>
              </div>

              <div v-if="buttonState === 'failure'" class="error-container">
                <span v-if="paymentConfig.paymentFailedText" v-html="paymentConfig.paymentFailedText" />
                <span v-else class="pay-error">
                  There was a problem processing your payment. Please try again, or continue without paying.
                </span>
              </div>

              <div class="pay-container">
                <v-btn
                  class="pay-button"
                  :disabled="buttonState === 'loading' || !validated"
                  :color="validated ? submitColor : 'grey'"
                  @click="submitPayment"
                  >{{ payBtnTxt }}</v-btn
                >
                <!-- <span v-if="autoPay" class="monthly-charge">+ ${{ monthlyTotal.toFixed(2) }}/mo</span> -->
              </div>

              <div v-if="buttonState === 'failure'" class="skip-container">
                <v-btn class="skip-button" @click="submitOrderWithoutPay">Submit Order (without paying)</v-btn>
              </div>
              <div class="skip-container" v-if="showSkipPayment">
                <v-btn class="skip-button" text @click="skipPayment">{{ skipPaymentText }}</v-btn>
              </div>
              <div v-if="!paymentConfig.payIconHigh" class="pay-methods">
                <span>Available Payment Methods:</span>
                <v-img src="/api/content/payment-methods.jpg" width="250" height="30" />
              </div>

              <div>
                <p v-if="paymentConfig.contactDetails" v-html="paymentConfig.contactDetails" class="location" />
                <p v-else class="location">
                  Vexus Fiber
                  <br />
                  4006 W Loop 289
                  <br />
                  Lubbock, TX 79407
                  <br />
                  <a :href="`tel:18006582150`">1-800-658-2150</a>
                </p>
              </div>
            </v-form>
          </div>
        </v-card>
      </v-dialog>
    </v-overlay>
  </div>
</div>
