<template>
  <v-container
    fluid
    style="max-width: 1800px"
    v-resize="onResize"
    :class="{
      'px-4': $vuetify.breakpoint.xsOnly,
      'px-1': $vuetify.breakpoint.smAndUp
    }"
  >
    <div class="offer-display-text" v-if="offerDisplayText">
      <v-row class="text-center">
        <v-col class="display-1" v-html="uiMacroParser(shopper, offerDisplayText)"></v-col>
      </v-row>
    </div>
    <div class="offer-display-text" v-else>
      <v-row class="text-center">
        <v-col class="display-1">Which services do you need?</v-col>
      </v-row>
    </div>
    <v-row v-if="showChoosePackageText" class="text-center">
      <v-col id="choose-package-text" class="headline pt-1" v-html="uiMacroParser(shopper, choosePackageText)"></v-col>
    </v-row>

    <v-container v-if="useButtonsForLobs" id="lob-buttons" class="d-flex justify-center">
      <v-row class="justify-center">
        <v-col
          :cols="$vuetify.breakpoint.xsOnly ? 12 : 'auto'"
          v-for="productType in viewableProductTypes"
          class="d-flex justify-center"
          :key="productType.name + '-button'"
        >
          <v-btn
            rounded
            :class="isProductTypeSelected(productType.name) ? 'active' : 'inactive'"
            :disabled="isProductTypeDisabled(productType.name)"
            @click="productTypeChanged(productType.name)"
            >{{ productType.name }}
            <v-spacer></v-spacer>
            <v-icon v-if="isProductTypeSelected(productType.name)">mdi-check</v-icon>
            <v-icon v-else>mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="underLobText">
      <v-row class="text-center under-lob-text">
        <v-col v-html="uiMacroParser(shopper, underLobText)"></v-col>
      </v-row>
    </v-container>

    <v-container v-if="showLobCheckboxes && !useButtonsForLobs" class="hidden-sm-and-up">
      <v-row dense v-for="productType in viewableProductTypes" :key="productType.name" class="py-0" justify="center">
        <v-card id="lob-checkboxes-row" flat min-width="75%">
          <v-col cols="12" class="py-0" id="lob-checkboxes">
            <v-checkbox
              dense
              :disabled="isProductTypeDisabled(productType.name)"
              v-model="selectedProductTypes"
              :label="productType.label"
              :value="productType.name"
              :color="lobTextColor"
              class="my-0"
              :class="
                $vuetify.breakpoint.xsOnly
                  ? `smaller-checkbox ${productType.name}-checkbox`
                  : `bigger-checkbox ${productType.name}-checkbox`
              "
            />
          </v-col>
        </v-card>
      </v-row>
    </v-container>

    <v-row v-if="showLobCheckboxes && !useButtonsForLobs" class="hidden-xs-only" justify="center" id="lob-checkboxes">
      <v-checkbox
        v-for="productType in viewableProductTypes"
        :disabled="isProductTypeDisabled(productType.name)"
        :key="productType.name"
        v-model="selectedProductTypes"
        :label="productType.label"
        :value="productType.name"
        color="primary"
        class="bigger-checkbox col-xs-12"
        :class="`${productType.name}-checkbox`"
        style="padding-right: 120px"
      />
    </v-row>

    <v-row v-if="filterPackages.length === 0 || filterPackages.length === undefined" class="text-center">
      <v-col id="no-offers-text" v-html="uiMacroParser(shopper, noOffersText)"></v-col>
    </v-row>

    <PackageContainer v-else :filterPackages="filterPackages" :packageSelected="packageSelected" />

    <!-- <div class="offers-container">
      <template v-for="(p, i) in filterPackages">
        <div :key="i" class="package pa-2">
          <component
            :is="packageComponent"
            :packageData="p"
            :colorIndex="i"
            @packageSelected="packageSelected"
            :minWidth="numPkgs === 4 ? 225 : 275"
          ></component>
        </div>
      </template>
    </div> -->
    <v-row v-if="offerDisclaimerText" class="text-center pt-3 pb-3" justify="center">
      <span class="pt-1" v-html="uiMacroParser(shopper, offerDisclaimerText)"></span>
    </v-row>
    <StepperButtons />
  </v-container>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
  WritableComputedRef
} from '@vue/composition-api'
import ecom from '../../gtm/ecom'
import ga4 from '@/gtm/ga4'
import { COMPONENT_ORDER, GET_CATALOG } from '@/store/types'
import { sortBy, flatten, uniq, isEqual } from 'lodash'
import useTermsOfService from '@/components/order/useTermsOfService'
import useOrder from '@/components/order/useOrder'
import StepperButtons from '@/components/order/StepperButtons.vue'
import useCatalogConfig from '@/components/shared/useCatalogConfig'
import { getConfigItem } from '../shared/getConfigItem'
import PackageContainer from './package/PackageContainer.vue'
import { uiMacroParser } from '@/utils/ShopperHelpers'
import { IShopper } from '@adg/catalog/src/modules/Shopper'
import {
  catalogRank,
  ConfigKeys,
  getItemPriceAsNumber,
  Catalog,
  Product,
  Package,
  ConvertedProductType
} from '@adg/catalog/src/modules/Catalog'
import $store from '@/store'
import usePackage from './package/usePackage'
import { broadbandLabelParser } from './package/useBroadbandLabel'

export default defineComponent({
  name: 'Offers',
  components: {
    PackageContainer,
    StepperButtons
  },
  setup(props, context) {
    const {
      emit,
      //root: { $store, $router }
      root: { $router, $vuetify } // try refactor out $store  need to also refactor out router .. dont know how
    } = context
    const { getTermsOfServiceDocument } = useTermsOfService()
    const { validation, currentStep, addressVerified } = useOrder($store, $router)
    const catalog = computed((): Catalog => $store.getters[GET_CATALOG])
    const shopper = computed(() => $store.getters.getShopper as IShopper)
    const packages = computed(() => (catalog.value ? catalog.value.Packages : []))
    const productTypes = computed(() => (catalog && catalog.value ? catalog.value.RefData['Product Types'] : []))
    const showLobCheckboxes = computed(() => getConfigItem(ConfigKeys.showLobCheckboxes) ?? true)

    const useButtonsForLobs = computed(() => getConfigItem(ConfigKeys.useButtonsForLobs) ?? false)

    const uiConfig = computed(() => $store.getters.getUIConfig)

    const defaultLobs = computed(() => getConfigItem(ConfigKeys.defaultLobs))

    const offerDisplayText = computed(() => getConfigItem(ConfigKeys.offerDisplayText))

    const offerDisclaimerText = computed(() => getConfigItem(ConfigKeys.offerDisclaimerText))

    const underLobText = computed(() => getConfigItem(ConfigKeys.underLobText))

    const choosePackageText = computed(
      () =>
        (selectedProductTypes.value.length > 1
          ? getConfigItem(ConfigKeys.bundlePackageText)
          : getConfigItem(ConfigKeys.singlePackageText)) ?? 'Choose your package'
    )

    const showChoosePackageText = computed(() => getConfigItem(ConfigKeys.showChoosePackageText) ?? true)

    const lobTextColor = computed(() => getConfigItem(ConfigKeys.lobTextColor) ?? 'primary darken')

    const { getProductTypeOfferLobs, getProductTypeLabel, isProductTypeHidden, isProductTypeDisabled } = useCatalogConfig($store)

    const viewableProductTypes = computed((): ConvertedProductType[] => {
      const sortOrder = (p: Product) => p[COMPONENT_ORDER]
      const allProducts = sortBy(flatten(packages.value.map((p) => p.Products)), sortOrder)
      const availableProductTypes = uniq(allProducts.map((p: any) => p['Product Type']))

      const filteredProductTypes = availableProductTypes.filter((p) => {
        return !isProductTypeHidden(p)
      })

      const converted = filteredProductTypes.map((pt) => {
        return convertProductType(pt)
      })
      return converted ?? []
    })

    const incomingLob = computed(() => {
      let incomingLobs = $store.getters.getIncomingLob

      if (!Array.isArray(incomingLobs)) {
        incomingLobs = [incomingLobs]
      }
      return incomingLobs
    })

    // const selectedProductTypes = ref([] as string[])
    const selectedProductTypes: WritableComputedRef<string[]> = computed({
      get: () => {
        return $store.getters.getSelectedProductTypes
      },
      set: (value) => {
        $store.commit('setSelectedProductTypes', value)
      }
    })

    // onMounted(() => {
    //   console.log('mounted offers', incomingLob.value, viewableProductTypes.value)
    //   if (incomingLob.value.length) {
    //     selectedProductTypes.value = incomingLob.value
    //   }
    // })

    const termsOfServiceDocument = computed(
      () => `/api/content/${getTermsOfServiceDocument(uiConfig.value.termsOfService || [], packages.value || []).link}`
    )

    const packageSelected = () => {
      emit('goForward')
    }

    const convertProductType = (pt): ConvertedProductType => {
      return {
        name: pt,
        label: getProductTypeLabel(pt),
        lobs: getProductTypeOfferLobs(pt)
      }
    }

    let lastSentImpressionsProductTypes: string[] | undefined = undefined

    const selectedLobs = computed(() =>
      uniq(
        flatten(
          selectedProductTypes.value.map((s) => {
            return getProductTypeOfferLobs(s)
          })
        )
      )
    )

    const catchAllProduct = computed(() => getConfigItem(ConfigKeys.catchAllProduct))

    const noOffersText = computed(
      () =>
        getConfigItem(ConfigKeys.noOffersAvailableText) ??
        '<span class="headline pt-1">No available offers meet your search criteria, please select one or more options above.</span>'
    )

    const filterPackages = computed(() => {
      const lobs = sortBy(selectedLobs.value)
      let pkgs
      if (catchAllProduct && lobs.includes(catchAllProduct.value)) {
        // if there is a catchAllProduct ("Special Offers"), select all packages that have a "Special Offer" product
        // regardless of other selected lobs
        pkgs = packages.value
          .filter((p) => sortBy(uniq(p.Products.map((p) => p['Product Type']))).includes(catchAllProduct.value))
          .sort((a, b) => {
            const aPrice = getItemPriceAsNumber(a, 'Monthly Price')
            const bPrice = getItemPriceAsNumber(b, 'Monthly Price')
            const aSpeed = catalogRank(a.Products?.find((prod) => prod['Product Type'] === 'Internet')?.Speed)
            const bSpeed = catalogRank(b.Products?.find((prod) => prod['Product Type'] === 'Internet')?.Speed)

            if (a.Rank && b.Rank) {
              if (a.Rank < b.Rank) {
                return -1
              } else if (a.Rank > b.Rank) {
                return 1
              }
            }

            if (aPrice && bPrice) {
              if (aPrice < bPrice) {
                return -1
              } else if (aPrice > bPrice) {
                return 1
              }
            }

            if (aSpeed && bSpeed) {
              if (aSpeed < bSpeed) {
                return -1
              }
              if (aSpeed > bSpeed) {
                return 1
              }
            }
            return 0

            // if (
            //   getItemPriceAsNumber(a, 'Monthly Price') - getItemPriceAsNumber(b, 'Monthly Price') == 0 &&
            //   a.Products.some((prod) => prod['Product Type'] == 'Internet')
            // ) {
            //   return (
            //     catalogRank(a.Products?.find((prod) => prod['Product Type'] === 'Internet')?.Speed) -
            //     catalogRank(b.Products?.find((prod) => prod['Product Type'] === 'Internet')?.Speed)
            //   )
            // }
            // return getItemPriceAsNumber(a, 'Monthly Price') - getItemPriceAsNumber(b, 'Monthly Price')
          })
      } else {
        //otherwise, do the normal filtering logic based on lobs
        pkgs = packages.value
          .filter((p) => p.View)
          .filter((p) => isEqual(sortBy(uniq(p.Products.map((p) => p['Product Type']))), lobs))
          .sort((a, b) => {
            const aPrice = getItemPriceAsNumber(a, 'Monthly Price')
            const bPrice = getItemPriceAsNumber(b, 'Monthly Price')
            const aSpeed = catalogRank(a.Products?.find((prod) => prod['Product Type'] === 'Internet')?.Speed)
            const bSpeed = catalogRank(b.Products?.find((prod) => prod['Product Type'] === 'Internet')?.Speed)

            if (a.Rank !== undefined && b.Rank !== undefined) {
              if (a.Rank < b.Rank) {
                return -1
              } else if (a.Rank > b.Rank) {
                return 1
              }
            }

            if (aPrice !== undefined && bPrice !== undefined) {
              if (aPrice < bPrice) {
                return -1
              } else if (aPrice > bPrice) {
                return 1
              }
            }

            if (aSpeed !== undefined && bSpeed !== undefined) {
              if (aSpeed < bSpeed) {
                return -1
              }
              if (aSpeed > bSpeed) {
                return 1
              }
            }
            return 0

            // if (
            //   getItemPriceAsNumber(a, 'Monthly Price') - getItemPriceAsNumber(b, 'Monthly Price') == 0 &&
            //   a.Products.some((prod) => prod['Product Type'] == 'Internet')
            // ) {
            //   return (
            //     catalogRank(a.Products?.find((prod) => prod['Product Type'] === 'Internet')?.Speed) -
            //     catalogRank(b.Products?.find((prod) => prod['Product Type'] === 'Internet')?.Speed)
            //   )
            // }
            // return getItemPriceAsNumber(a, 'Monthly Price') - getItemPriceAsNumber(b, 'Monthly Price')
          })
      }
      // Since this is in a computed field it may fire often.  We really only want the impressions sent
      // if the packages have changed (ie the selected product types are not the same as the last time we sent )
      // console.log(JSON.stringify(lastSentImpressionsProductTypes), 'not equals', JSON.stringify(selectedProductTypes.value))
      if (JSON.stringify(lastSentImpressionsProductTypes) != JSON.stringify(selectedProductTypes.value)) {
        //event seems to be firing on other pages, so let's restrict the impressions to the page where
        // you'd expect them to fire
        const page = window.location.href
        if (pkgs.length > 0 && addressVerified.value && (page.indexOf('/order/2') != -1 || page.indexOf('/order/1') != -1)) {
          ecom.pushPackageImpressions(pkgs)
          ga4.pushViewItemList(pkgs)
          lastSentImpressionsProductTypes = selectedProductTypes.value
          const labelsGenerated = computed((): any[] => $store.getters.getLabelsGenerated)

          pkgs = handleBBLs(pkgs, labelsGenerated.value)
          return pkgs
        }
      }
    })

    const numPkgs = computed(() => (filterPackages && filterPackages.value ? filterPackages.value.length : 3))
    const md = computed(() => {
      if (numPkgs.value === 1) {
        return 12
      } else if (numPkgs.value === 2) {
        return 6
      } else if (numPkgs.value === 3) {
        return 4
      } else {
        return windowSize.value.x < 1024 ? 6 : 3
      }
    })
    const sm = computed(() => {
      if (numPkgs.value === 3) {
        return windowSize.value.x < 1024 ? 12 : 4
      } else if (numPkgs.value === 1) {
        return 12
      } else {
        return 6
      }
    })

    const windowSize = ref({
      x: 0,
      y: 0
    })

    const onResize = () => {
      windowSize.value = { x: window.innerWidth, y: window.innerHeight }
    }

    const initialize = () => {
      lastSentImpressionsProductTypes = undefined
      const localIncomingLobs = incomingLob.value.filter((lob) => viewableProductTypes.value?.find((pt) => pt.name === lob))
      const localDefaultLobs = defaultLobs.value.filter((lob) => viewableProductTypes.value?.find((pt) => pt.name === lob))
      selectedProductTypes.value = ['Internet'] //first set to global default of internet
      if (localDefaultLobs.length > 0) {
        //if there are local defaults (from uiConfig/catalog), use those
        selectedProductTypes.value = localDefaultLobs
      }
      if (localIncomingLobs.length > 0) {
        //if there are incoming lobs (from url params), use those
        selectedProductTypes.value = localIncomingLobs
      }
    }

    watch(viewableProductTypes, (pt, prePt) => {
      initialize()
    })

    onMounted(() => {
      initialize()
    })

    const productTypeChanged = (productType: string) => {
      if (selectedProductTypes.value.includes(productType)) {
        selectedProductTypes.value = selectedProductTypes.value.filter((pt) => pt !== productType)
      } else {
        selectedProductTypes.value = [...selectedProductTypes.value, productType]
      }
    }

    const isProductTypeSelected = (productType: string) => {
      return selectedProductTypes.value.includes(productType)
    }

    // const getLabelHtmlForPackageName = (name: string) => {
    //   return $store.getters.getLabelsGenerated.value.find((label) => label.packageName === name)?.html ?? undefined
    // }

    const handleBBLs = (packages: Package[], labelsGenerated: any[]): Package[] => {
      const { broadbandConfigString, broadbandLabelPlacement, approvedBroadbandLabelClasses } = usePackage($store, $vuetify, emit)
      // const getLabelHtml = $store.getters.getLabelsGenerated.value.find((label) => label.packageName === p.Name)?.html ?? undefined
      packages.forEach((p: Package) => {
        const getLabelHtml = labelsGenerated?.find((label) => label.packageName === p.Name)?.html ?? undefined
        if (!getLabelHtml && !!p.BroadbandLabel?.[0]) {
          const label = broadbandLabelParser(
            shopper.value,
            p,
            broadbandConfigString.value,
            approvedBroadbandLabelClasses.value,
            broadbandLabelPlacement.value,
            p.BroadbandLabel?.[0]
          )
          p.uniquePlanId = label.uniquePlanId
          $store.commit('addGeneratedLabel', { packageName: p.Name, html: label.html, uniquePlanId: label.uniquePlanId })
        }
      })

      return packages
    }

    return {
      offerDisplayText,
      offerDisclaimerText,
      catalog,
      selectedProductTypes,
      packages,
      productTypes,
      filterPackages,
      packageSelected,
      sm,
      md,
      onResize,
      windowSize,
      numPkgs,
      viewableProductTypes,
      termsOfServiceDocument,
      currentStep,
      validation,
      emit,
      isProductTypeDisabled,
      noOffersText,
      choosePackageText,
      lobTextColor,
      showLobCheckboxes,
      showChoosePackageText,
      productTypeChanged,
      isProductTypeSelected,
      underLobText,
      useButtonsForLobs,
      uiMacroParser,
      shopper
    }
  }
})
</script>

<style scoped>
.smaller-checkbox {
  font-weight: 100;
  transform: scale(1.4);
  transform-origin: left;
  border-width: thin;
}

.bigger-checkbox {
  font-weight: 100;
  transform: scale(1.65);
  transform-origin: left;
  border-width: thin;
}
</style>
