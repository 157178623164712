<template>
  <v-card id="cart" :tile="sharpCorners" :ripple="false" :show="selectedPackage" class="lg tr-0 tl-0">
    <!-- <component
      :is="headerComponent"
      :title="headerText"
      :image="headerIconOrImage"
      :color="cartHeaderColor"
      @headerClicked="cartClicked"
    /> -->
    <CardHeader
      :title="cartHeaderText"
      :image="cartHeaderImage"
      :color="cartHeaderBackgroundColor"
      :isCartHeader="true"
      @headerClicked="cartClicked"
      @cartHeaderCloseButtonClicked="cartClicked"
    ></CardHeader>
    <v-container class="package-display-name" @click="cartClicked">
      <v-row v-if="showCartPackagePlanSection">
        <v-col cols="12" class="cart-header"> Your Plan </v-col>
        <v-col cols="12" class="pt-0">
          <CartLineItem
            :expanded="expandCartLineItem"
            :item="monthlyCharges.find((charge) => charge.itemType === 'Package')"
            priceType="Monthly Price"
            :duration="durationInLineItem ? ' /month' : ''"
          ></CartLineItem>
        </v-col>
      </v-row>
      <v-row v-if="$vuetify.breakpoint.smAndUp">
        <v-col class="headline grey--text package-display-name-cart" id="customize-group-header"
          >{{ packageDisplayNameOrDescription }}<TooltipDebug :item="shoppingCart" :name="'Cart Order to Submit'"
        /></v-col>
      </v-row>
      <v-row v-if="$vuetify.breakpoint.xsOnly" :class="$vuetify.breakpoint.xs ? ['pt-2', 'text-center'] : ''">
        <v-col
          class="title grey--text package-display-name-cart"
          id="customize-group-header"
          v-html="packageDisplayNameOrDescription"
        ></v-col>
      </v-row>
    </v-container>

    <v-img v-if="cartImage" @click="cartClicked" :src="cartImage" alt="" title="" />

    <MonthlyPriceDisplay
      v-if="displayMonthlyPriceTopOfCart"
      :item="cartTotal"
      priceType="Monthly Price"
      :myComponentColor="monthlyTotalColor"
      additionalTextClasses="font-weight-bold title"
      additionalPriceClasses="font-weight-bold title"
    />

    <!--      ONETIME TOTAL      -->
    <div v-if="false">
      <v-container class="py-0" @click="cartClicked">
        <v-row v-if="$vuetify.breakpoint.smAndDown" class="grey lighten-3 text-center primary--text">
          <v-col cols="12" class="headline font-weight-bold pb-0">OneTime Total </v-col>
          <v-col cols="12" class="headline font-weight-bold pt-0">
            {{ coreCurrency(oneTimeTotal) }}
          </v-col>
        </v-row>
      </v-container>
      <v-container class="pt-0">
        <v-row v-if="$vuetify.breakpoint.mdAndUp" class="grey lighten-3 primary--text">
          <v-col cols="9" class="title font-weight-bold">OneTime Total</v-col>
          <v-col class="title text-right pt-3 font-weight-bold" cols="3">{{ coreCurrency(oneTimeTotal) }}</v-col>
        </v-row>
      </v-container>
    </div>

    <!--    CART        -->
    <v-container class="pt-0 pb-3" :key="getName(selectedPackage)">
      <v-row :class="$vuetify.breakpoint.xs ? ['text-center'] : ''">
        <v-col class="title" id="customize-group-header" v-html="monthlyChargesCartLabel"></v-col>
      </v-row>

      <!-- <v-row v-for="item in monthlyCharges" :key="item.name">
        <v-col class="py-0" v-if="item" cols="8">{{ itemDisplayName(item) }} </v-col>
        <v-col class="py-0 text-right" v-if="item" cols="4">
          <span>{{ getPrice(item, 'Monthly Price') | coreCurrency }}</span>
        </v-col>
      </v-row> -->
      <div v-for="(item, i) in monthlyCharges" :key="`${getName(item)}${i}`">
        <CartLineItem
          v-if="!showCartPackagePlanSection || (showCartPackagePlanSection && item.itemType !== 'Package')"
          :expanded="expandCartLineItem"
          :item="item"
          priceType="Monthly Price"
          :duration="(item['Monthly Price'] || item?.Fees?.[0]?.['Monthly Price']) && durationInLineItem ? ' /month' : ''"
        />
      </div>
      <v-row>
        <v-col class="py-2"></v-col>
      </v-row>
      <MonthlyPriceDisplay
        v-if="!displayMonthlyPriceTopOfCart"
        :item="cartTotal"
        priceType="Monthly Price"
        :myComponentColor="monthlyTotalColor"
        additionalTextClasses="font-weight-bold title"
        additionalPriceClasses="font-weight-bold title"
      />
      <div class="pa-0 ma-0" v-if="oneTimeCharges.length > 0">
        <v-divider></v-divider>
        <v-row :class="$vuetify.breakpoint.xs ? ['pt-2', 'text-center'] : ''">
          <v-col class="title" id="customize-group-header">{{ oneTimeChargesLabel }}</v-col>
        </v-row>
      </div>
      <div v-for="(item, i) in oneTimeCharges" :key="`${getName(item)}${i}`">
        <CartLineItem :expanded="expandCartLineItem" :item="item" priceType="OTC" />
      </div>
      <v-row v-if="oneTimeCharges && displayOtcTotalInCart" align="start" class="lighten-3 pt-2 px-0 ma-0">
        <v-col class="pa-0" cols="8">One Time Charges Total</v-col>
        <v-col class="pa-0 text-right" cols="4">
          <span>${{ oneTimeChargesTotal.toFixed(2) }}</span>
        </v-col>
      </v-row>
      <!-- <v-row v-for="item in oneTimeCharges" :key="item.Name">
        <v-col class="py-0" v-if="item && item.qty" cols="8">
          <span>{{ itemDisplayName(item) }} ({{ item.qty }})<Tooltip :payload="item" /> </span>
        </v-col>
        <v-col class="py-0" v-if="item && !item.qty" cols="8">
          <span>{{ itemDisplayName(item) }} <Tooltip :payload="item" /> </span>
        </v-col>
        <v-col class="py-0 text-right" v-if="item" cols="4">{{ getPrice(item, 'OTC') | coreCurrency }}</v-col>
      </v-row> -->
      <div v-if="showCartDueMonthly">
        <br />
        <v-divider></v-divider>
        <v-row>
          <v-col id="customize-group-header" class="pb-0 title">Due Monthly</v-col>
          <v-col class="pt-4 text-right" cols="4">{{ coreCurrency(monthlyTotal) }}</v-col>
        </v-row>
      </div>
      <div v-if="showCartDueToday">
        <br />
        <v-divider></v-divider>
        <v-row>
          <v-col id="customize-group-header" class="pb-0 title">Due today</v-col>
          <v-col class="pt-4 text-right" cols="4">{{ coreCurrency(grandTotal) }}</v-col>
        </v-row>
      </div>

      <v-divider v-if="promo"></v-divider>

      <v-row v-if="promo" :class="$vuetify.breakpoint.xs ? ['pt-2', 'text-center'] : ''">
        <v-col class="title promo-title">Promo Code: </v-col>
      </v-row>
      <v-row v-if="promo">
        <v-col class="py-0" cols="8"
          ><span v-html="uiMacroParser(shopper, itemDisplayName(promo['Billing Codes'][0]))"></span
        ></v-col>
        <v-col class="py-0 text-right" cols="4"><a @click="removePromo">Remove</a> </v-col>
      </v-row>
      <div v-if="promoAvailable && !promo && currentStepPromoAllowed(currentStep) && !showCartPromoToggleField">
        <v-divider></v-divider>
        <v-row :class="$vuetify.breakpoint.xs ? ['pt-2', 'text-center'] : ''">
          <v-col cols="12" class="title">Have a promo code? </v-col>
          <v-col cols="12" class="py-0">
            <v-text-field
              class="promo-text-field"
              :rules="[promoRule]"
              validate-on-blur
              outlined
              flat
              dense
              v-model="promoInput"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="py-0">
          <v-col class="py-0" align="end">
            <v-btn class="promo-apply-button" small color="primary" :class="defaultButtonColors()" @click="applyPromo">Apply</v-btn>
          </v-col>
        </v-row>
      </div>
      <div v-else-if="promoAvailable && !promo">
        <v-row>
          <v-col cols="6">
            <v-btn @click="() => (promoFieldToggled = !promoFieldToggled)" class="toggle-promo-button">Add promotion</v-btn>
          </v-col>
        </v-row>
        <v-row v-if="promoFieldToggled">
          <v-col cols="9" class="py-0">
            <v-text-field
              class="promo-text-field"
              :rules="[promoRule]"
              validate-on-blur
              outlined
              flat
              dense
              v-model="promoInput"
            ></v-text-field>
          </v-col>
          <v-col cols="3" class="py-0" align="end">
            <v-btn class="promo-apply-button" small color="primary" :class="defaultButtonColors()" @click="applyPromo">Apply</v-btn>
          </v-col>
        </v-row>
      </div>
      <div v-if="showPrePay">
        <br />
        <v-divider />
        <v-row>
          <v-col class="pb-0 title">Payment Required Today</v-col>
          <v-col class="pt-4 text-right" cols="4">{{ coreCurrency(grandTotal) }}</v-col>
        </v-row>
      </div>
      <v-row class="py-0" v-for="(disclaimer, index) in getAllDisclaimers" :key="index + 'alldisclaimer'">
        <v-col cols="12" class="caption text--secondary">
          <span v-html="uiMacroParser(shopper, disclaimer)" />
        </v-col>
      </v-row>
      <Transunion />
    </v-container>
  </v-card>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from '@vue/composition-api'
import useCart from './useCart'
import CardHeader from '@/components/layout/CardHeader.vue'
import { mdiInformation } from '@mdi/js'
import Tooltip from '@/components/shared/tooltip/Tooltip.vue'
import TooltipDebug from '@/components/shared/tooltip/TooltipDebug.vue'
import useOrder from '@/components/order/useOrder'
import useButtonColors from '@/components/shared/useButtonColors'
import { getConfigItem } from '@/components/shared/getConfigItem'
import Transunion from '@/components/shared/Transunion.vue'
import { FeedItem, itemDisplayName } from '@adg/catalog/src/modules/Catalog'
import { ConfigKeys } from '@adg/catalog/src/modules/Catalog'
import creditCheck from '@/components/shared/creditCheck/useCreditCheck'
import CartLineItem from './CartLineItem.vue'
import MonthlyPriceDisplay from './MonthlyPriceDisplay.vue'
import $store from '@/store'
import ga4 from '@/gtm/ga4'
import { coreCurrency } from '@adg/catalog/src/common/filters'
import { uiMacroParser } from '@/utils/ShopperHelpers'
import { IShopper } from '@adg/catalog/src/modules/Shopper'

export default defineComponent({
  name: 'Cart',
  components: {
    CardHeader,
    Tooltip,
    TooltipDebug,
    Transunion,
    CartLineItem,
    MonthlyPriceDisplay
  },
  setup(props, { emit, root }) {
    const { $vuetify, $router } = root as any
    const cartClicked = () => {
      if ($vuetify.breakpoint.smAndDown) emit('cartClicked')
    }

    const cartHeaderImage = computed(() => getConfigItem(ConfigKeys.cartHeaderImage) ?? 'CartIcon.png')
    const cartHeaderText = computed(() => getConfigItem(ConfigKeys.cartHeaderText) ?? 'YOUR PACKAGE')
    const monthlyTotalText = computed(() => getConfigItem(ConfigKeys.monthlyTotalText) ?? 'Monthly Total')
    const cartHeaderBackgroundColor = computed(() => getConfigItem(ConfigKeys.cartHeaderBackgroundColor) ?? 'secondaryDark')
    const sharpCorners = computed(() => getConfigItem(ConfigKeys.cardSharpCorners) ?? false)
    const monthlyTotalColor = computed(() => getConfigItem(ConfigKeys.monthlyTotalColor) ?? 'primary')
    const displayMonthlyPriceTopOfCart = computed(() => getConfigItem(ConfigKeys.displayMonthlyPriceTopOfCart) ?? true)
    const shopper = computed(() => $store.getters.getShopper as IShopper)
    const showCartPackagePlanSection = computed(() => getConfigItem(ConfigKeys.showCartPackagePlanSection) ?? false)
    const showCartDueMonthly = computed(() => getConfigItem(ConfigKeys.showCartDueToday) ?? false)
    const showCartDueToday = computed(() => getConfigItem(ConfigKeys.showCartDueToday) ?? false)
    const showCartPromoToggleField = computed(() => getConfigItem(ConfigKeys.showCartPromoToggleField) ?? false)
    const promoFieldToggled = ref(false)
    const oneTimeChargesLabel = computed(() => getConfigItem(ConfigKeys.oneTimeChargesLabel) ?? 'One Time Charges: ')
    const durationInLineItem = computed(() => getConfigItem(ConfigKeys.durationInLineItem) ?? false)

    const monthlyChargesCartLabel = computed(() => {
      const macro = getConfigItem(ConfigKeys.monthlyChargesCartLabelMacro) ?? undefined
      return macro ? uiMacroParser(shopper.value, macro, true) : 'Monthly Charges: '
    })

    const expandCartLineItem = computed(() => getConfigItem(ConfigKeys.expandCartLineItem) ?? false)

    let promoInput = ref('')

    const cartImage = computed(() => getConfigItem(ConfigKeys.cartImage))

    const applyPromo = () => {
      selectedPackage.value?.Promos?.forEach((p) => {
        const found = p['Billing Codes']?.find((bc) => {
          if (p.REGEX) {
            const regex = new RegExp(p.REGEX)
            const regexMatched = regex.test(promoInput.value)
            if (regexMatched) {
              $store.commit('setAccountField', { field: 'customInfo.variablePromotionCode', value: promoInput.value })
            }
            return regexMatched
          } else return itemDisplayName(bc).toUpperCase() === promoInput.value.toUpperCase()
        })
        if (found) {
          const promo = { ...p, 'Billing Codes': [found] }
          $store.commit('setPromo', promo)
          ga4.pushPromo('select_promotion', promo, 'cart')
          promoInput.value = ''
        }
      })
    }

    const promoRule = (value: string) => {
      const promoExists = selectedPackage.value?.Promos?.some((p) =>
        p['Billing Codes']?.find((bc) => {
          if (p.REGEX) {
            const regex = new RegExp(p.REGEX)
            const regexMatched = regex.test(promoInput.value)
            return regexMatched
          } else return itemDisplayName(bc).toUpperCase() === promoInput.value.toUpperCase()
        })
      )
      return promoExists || 'Promo code: ' + value + ' not valid'
    }

    const packageDisplayNameOrDescription = computed(() => {
      if (selectedPackage.value) {
        return getConfigItem(ConfigKeys.usePackageDescriptionInCart)
          ? selectedPackage.value.Description
          : itemDisplayName(selectedPackage.value)
      } else {
        return ''
      }
    })

    const {
      selectedPackage,
      promo,
      promoAvailable,
      monthlyCharges,
      getPrice,
      oneTimeCharges,
      monthlyTotal,
      oneTimeTotal,
      grandTotal,
      removePromo,
      getAllDisclaimers,
      cartTotal,
      shoppingCart
    } = useCart($store)

    const displayOtcTotalInCart = computed(() => getConfigItem(ConfigKeys.displayOtcTotalInCart) ?? false)

    const otcPrices = computed(() => oneTimeCharges.value.map((charge) => (typeof charge.OTC === 'number' ? charge.OTC : 0)))

    const oneTimeChargesTotal = computed(() => {
      let total = 0
      otcPrices.value.forEach((price) => {
        total += price
      })
      return total
    })

    //const order = computed(orderToSubmit)
    // watch(
    //   shoppingCart,
    //   (newval, oldval) => {
    //     //if (!isEqual(newval, oldval)) $store.commit('setShopperOrder', newval)
    //     $store.commit('setShopperOrder', newval)
    //   },
    //   { deep: true }
    // )

    const { currentStep } = useOrder($store, $router)

    const { defaultButtonColors } = useButtonColors()

    const { mustPrePay } = creditCheck()

    const showPrePay = computed(() => mustPrePay.value && currentStep.value === 5)
    const getName = (p: FeedItem) => {
      return p?.Name ?? ''
    }
    const promoStepsAllowed = computed(() => getConfigItem(ConfigKeys.promoStepsAllowed) ?? [1, 2, 3, 4])
    const currentStepPromoAllowed = (step: number) => {
      return promoStepsAllowed.value.includes(step)
    }
    return {
      getName,
      itemDisplayName,
      cartImage,
      defaultButtonColors,
      selectedPackage,
      promo,
      promoAvailable,
      oneTimeCharges,
      monthlyTotal,
      oneTimeTotal,
      grandTotal,
      promoInput,
      applyPromo,
      removePromo,
      monthlyCharges,
      monthlyTotalText,
      cartClicked,
      mdiInformation,
      currentStep,
      getPrice,
      promoRule,
      getAllDisclaimers,
      showPrePay,
      cartTotal,
      shoppingCart,
      cartHeaderImage,
      cartHeaderText,
      cartHeaderBackgroundColor,
      monthlyTotalColor,
      sharpCorners,
      packageDisplayNameOrDescription,
      oneTimeChargesTotal,
      otcPrices,
      displayOtcTotalInCart,
      expandCartLineItem,
      currentStepPromoAllowed,
      coreCurrency,
      displayMonthlyPriceTopOfCart,
      uiMacroParser,
      shopper,
      monthlyChargesCartLabel,
      showCartPackagePlanSection,
      showCartDueMonthly,
      showCartDueToday,
      showCartPromoToggleField,
      promoFieldToggled,
      oneTimeChargesLabel,
      durationInLineItem
    }
  }
})
</script>

<style scoped>
.box {
  width: 40%;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.2);
  padding: 35px;
  border: 2px solid #fff;
  border-radius: 20px/50px;
  background-clip: padding-box;
  text-align: center;
}

.button {
  font-size: 1em;
  padding: 10px;
  color: #fff;
  border: 2px solid #06d85f;
  border-radius: 20px/50px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease-out;
}
.button:hover {
  background: #06d85f;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
}
.overlay:target {
  visibility: visible;
  opacity: 1;
}

.popup {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 30%;
  position: relative;
  transition: all 5s ease-in-out;
}

.popup h2 {
  margin-top: 0;
  color: #333;
  /* font-family: Tahoma, Arial, sans-serif; */
}
.popup .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}
.popup .close:hover {
  color: #06d85f;
}
.popup .content {
  max-height: 30%;
  overflow: auto;
}
.package-text {
  color: #08131f;
}
.monthly-charges {
  color: #08131f;
  font-weight: bold;
}
.one-time-charges {
  color: #08131f;
  font-weight: bold;
}
</style>
