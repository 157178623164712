<template>
  <v-card :tile="sharpCorners" class="lg tr-0 tl-0 mb-5 internet-card">
    <!-- <component
      :is="headerComponent"
      :title="headerText"
      :image="headerIconOrImage"
      :componentOrder="product.displayOrder"
      :headerTextColor="headerComponent === 'CardHeaderBluepeak' ? '#08131f' : ''"
    /> -->
    <CardHeader
      :title="internetHeaderText"
      :image="internetHeaderImage"
      :componentOrder="product?.displayOrder"
      :color="internetHeaderBackgroundColor"
    ></CardHeader>
    <v-container class="" id="internet">
      <PackageUpgrades :product="product" />
      <v-row :class="$vuetify.breakpoint.xs ? ['pt-2', 'text-center'] : ''" dense v-if="noItemsToShow && internetNoOptionsMessage">
        <v-col class="title primary--text" v-html="uiMacroParser(shopper, internetNoOptionsMessage)"></v-col>
      </v-row>
      <div v-for="group in productGroups" :key="group.group">
        <div v-if="notAllExcluded(group.products)">
          <v-row :class="$vuetify.breakpoint.xs ? ['pt-2', 'text-center'] : ''">
            <v-col class="py-0 title primary--text" id="customize-group-header"
              >{{ getGroupTitle(group.group) }} <Tooltip :payload="{ Name: group.group }" /><TooltipDebug
                :item="group"
                :name="getGroupTitle(group.group)"
              />
            </v-col>
          </v-row>
          <v-row v-if="getGroupSubtitle(group.group)" :class="$vuetify.breakpoint.xs ? 'text-center' : ''">
            <v-col class="py-0" v-html="getGroupSubtitle(group.group)"> </v-col>
          </v-row>

          <v-row v-if="group.products.length > 0 && radioGroup(group.products)">
            <v-container class="pl-1">
              <v-radio-group
                :mandatory="false"
                @change="equipmentChanged"
                v-model="choices[group.group]"
                class="pt-0 ma-0"
                :key="renderMe"
              >
                <div v-for="p in group.products" :key="p.Name + renderMe">
                  <v-row v-if="shouldShowProduct(p)" dense class="py-0 px-0">
                    <v-col cols="9" class="py-1">
                      <v-row class="pl-5 radio-row" style="flex-wrap: nowrap">
                        <v-radio class="py-0 black--text" :class="getCatalogItemClassName(p.Name, p.itemType)" :value="p.Name" />
                        <span v-html="uiMacroParser(shopper, itemDisplayName(p))"></span>
                        <Tooltip :payload="p" /><TooltipDebug :item="p" />
                      </v-row>
                      <v-row class="upgrade-radio-subtitle" v-if="getProductSubtitle(p)">
                        <v-col cols="12" class="py-0" v-html="uiMacroParser(shopper, getProductSubtitle(p) ?? '')"></v-col>
                      </v-row>
                    </v-col>
                    <v-col v-if="false /* old Logic */" cols="3" class="text-right pt-1"> Included </v-col>
                    <v-col v-else-if="getItemPrice(p, 'Monthly Price')" cols="3" class="text-right pt-1">
                      <span
                        v-html="uiMacroParser(shopper, p.PricePrefix ?? '') + ' ' + coreCurrency(getItemPrice(p, 'Monthly Price'))"
                      ></span>
                    </v-col>
                    <v-col v-else cols="3" class="text-right pt-1">
                      <span
                        v-html="uiMacroParser(shopper, p.PricePrefix ?? '') + ' ' + coreCurrency(getItemPrice(p, 'OTC'))"
                      ></span>
                    </v-col>
                  </v-row>
                  <div v-for="(c, i) in findChildren(p, equipment)" :key="c.Name + i + renderMe">
                    <v-row v-if="productPicker(c) && c.Parent && isVisibleRadio(c)">
                      <v-col cols="9" style="padding-left: 51px" class="py-1">
                        <ProductPicker
                          :product="choices[c.Name] ? choices[c.Name] : c"
                          :min="c.Min"
                          :max="c.Max"
                          @input="equipmentChanged"
                          v-model="choices[c.Name]"
                          :render-me="renderMe"
                        /><TooltipDebug :item="c" />
                      </v-col>
                      <v-col cols="3" class="text-right pt-1">
                        <span v-if="choices[c.Name]">{{ coreCurrency(choices[c.Name].calculatedPrice) }}</span>
                        <span v-if="!choices[c.Name]">{{ coreCurrency(0) }}</span>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-radio-group>
            </v-container>
          </v-row>

          <v-list v-else>
            <div v-for="p in group.products" :key="p.Name">
              <ProductPickerLineItem
                v-if="isProductPicker(p) && shouldShowProduct(p)"
                v-model="choices[p.Name]"
                :item="choices[p.Name] ? choices[p.Name] : p"
                @input="equipmentChanged"
                :render-me="renderMe"
                :max="p.Max"
                :min="p.Min"
              />
              <v-list-item v-else-if="shouldShowProduct(p)" :ripple="false">
                <template v-slot:default="{ active, toggle }">
                  <v-list-item-action>
                    <v-checkbox
                      :disabled="p.included === '1' || p.required === '1' || isTvRequired(p.Name)"
                      hide-details
                      class="my-1"
                      :class="getCatalogItemClassName(p.Name, p.itemType)"
                      v-model="choices[p.Name]"
                      :true-value="p.Name"
                      :false-value="null"
                      @change="equipmentChanged"
                      :key="renderMe"
                    >
                    </v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span v-html="uiMacroParser(shopper, itemDisplayName(p))"></span>
                      <Tooltip :payload="p" /><TooltipDebug :item="p" />
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="getProductSubtitle(p)">{{ getProductSubtitle(p) }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action v-if="false /* old Logic */">Included</v-list-item-action>
                  <v-list-item-action v-else-if="getItemPrice(p, 'Monthly Price')"
                    ><span
                      v-html="uiMacroParser(shopper, p.PricePrefix ?? '') + ' ' + coreCurrency(getItemPrice(p, 'Monthly Price'))"
                    ></span
                  ></v-list-item-action>
                  <v-list-item-action v-else
                    ><span v-html="uiMacroParser(shopper, p.PricePrefix ?? '') + ' ' + coreCurrency(getItemPrice(p, 'OTC'))"></span
                  ></v-list-item-action>
                </template>
              </v-list-item>
              <div v-for="c in findChildren(p, equipment)" :key="c.Name + renderMe">
                <v-row v-if="productPicker(c) && c.Parent && isVisible(c)">
                  <v-col cols="9" style="padding-left: 51px" class="py-1">
                    <ProductPicker
                      :product="choices[c.Name] ? choices[c.Name] : c"
                      :min="c.Min"
                      :max="c.Max"
                      @input="equipmentChanged"
                      v-model="choices[c.Name]"
                      :render-me="renderMe"
                      :key="c.Name + renderMe"
                    /><TooltipDebug :item="c" />
                  </v-col>
                  <v-col cols="3" class="text-right pt-1">
                    <span v-if="choices[c.Name]">{{ coreCurrency(choices[c.Name].calculatedPrice) }}</span>
                    <span v-if="!choices[c.Name]">{{ coreCurrency(0) }}</span>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-list>
        </div>
      </div>
    </v-container>
    <div v-if="additionalServicesInsideInternetCard" id="additional-services-internet">
      <div class="title primary--text">Add Additional Services</div>
      <AdditionalServices />
    </div>
  </v-card>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref } from '@vue/composition-api'
import ProductPicker from '@/components/shared/ProductPicker.vue'
import PackageUpgrades from '@/components/shared/PackageUpgrades.vue'
import Tooltip from '@/components/shared/tooltip/Tooltip.vue'
import TooltipDialog from '@/components/shared/tooltip/TooltipDialog.vue'
import CardHeader from '@/components/layout/CardHeader.vue'
import { bus } from '@/main'
import { flatten, min, max, mean, groupBy, toArray } from 'lodash'
import useUiConfig from '@/components/shared/useUiConfig'
import useComponentUtil from '@/components/shared/useComponentUtil'
import { mdiInformation } from '@mdi/js'
import {
  itemDisplayName,
  getItemPrice,
  addCalculatedPrice,
  ConfigKeys,
  nullCheck,
  qtyCheck,
  Package,
  isProductPicker
} from '@adg/catalog/src/modules/Catalog'
import TooltipDebug from '@/components/shared/tooltip/TooltipDebug.vue'
import { getConfigItem } from '@/components/shared/getConfigItem'
import $store from '@/store'
import ProductPickerLineItem from '@/components/shared/ProductPickerLineItem.vue'
import { getCatalogItemClassName } from '@adg/catalog/src/common/utils'
import { coreCurrency } from '@adg/catalog/src/common/filters'
import { uiMacroParser } from '@/utils/ShopperHelpers'
import { IShopper } from '@adg/catalog/src/modules/Shopper'
import AdditionalServices from '@/components/order/AdditionalServices.vue'

export default defineComponent({
  name: 'Internet',
  props: {
    product: Object,
    sharpCorners: Boolean
  },
  components: {
    ProductPicker,
    ProductPickerLineItem,
    CardHeader,
    PackageUpgrades,
    Tooltip,
    TooltipDialog,
    TooltipDebug,
    AdditionalServices
  },
  setup(props) {
    const shopper = computed(() => $store.getters.getShopper as IShopper)
    const additionalServicesInsideInternetCard = computed(
      () => getConfigItem(ConfigKeys.additionalServicesInsideInternetCard) ?? false
    )

    const renderMe = ref(0)
    const currentPackage = computed((): Package => $store.getters.getPackage)

    const dividersInCards = computed(() => getConfigItem(ConfigKeys.dividersInCards) ?? false)

    const noItemsToShow = computed(() => {
      const rest = [...props.product?.Upgrades, ...props.product?.Equipment]
      if (rest.length > 0) {
        return !notAllExcluded(rest)
      }
      return true
    })

    const dependenciesMet = (product) => {
      const parent = product.Parent
      const result = parent ? [parent].every(dependencyExists) : true
      return result
    }

    const internetNoOptionsMessage = computed(() => {
      return getConfigItem(ConfigKeys.internetNoOptionsMessage) ?? 'You have our fastest speed, please click Next to continue.'
    })

    const internetHeaderImage = computed(() => getConfigItem(ConfigKeys.internetHeaderImage) ?? 'InternetIcon.png')
    const internetHeaderText = computed(() => getConfigItem(ConfigKeys.internetHeaderText) ?? 'CUSTOMIZE YOUR INTERNET')
    const internetHeaderBackgroundColor = computed(() => getConfigItem(ConfigKeys.internetHeaderBackgroundColor) ?? undefined)

    const isVisible = (product) => {
      return dependenciesMet(product)
    }

    const isVisibleRadio = (product) => {
      return dependenciesMet(product)
    }

    const dependencyExists = (dependency) => {
      const items = toArray(choices)
      const exists = items.find((i) => i !== null && i === dependency)
      return exists !== undefined
    }

    const { checkbox, productPicker, radioGroup, findChildren } = useComponentUtil(props)

    const dialog = ref(false)
    let choices: Record<string | any, any> = reactive({})
    const { getGroupTitle, getMarketingInfo, getProductSubtitle, getGroupSubtitle } = useUiConfig()

    const parentExists = (v) => {
      if (v.Parent) {
        if (v.Parent in choices) {
          // Parent property exists in Model
          return nullCheck(choices[v.Parent]) && qtyCheck(choices[v.Parent])
        } else if (flatten(toArray(choices)).some((choice: any) => choice.Name === v.Parent)) {
          return true
        } else if (Object.values(choices).includes(v.Parent)) {
          return true
        } else {
          //If parent is not in current component state, check to see if it is anywhere else in cart.
          // removed because cartItems hasn't been updated
          // const t = cartItems.value.find((c) => c.Name === v.Parent)
          return false
        }
      } else {
        return true
      }
    }

    const equipmentChanged = (val) => {
      const allItems = flatten(toArray(choices).filter(nullCheck))
      const strings = allItems.filter((a) => typeof a === 'string')
      const objs = allItems.filter((a) => typeof a !== 'string')
      const stringToObjects = props.product?.Equipment.filter((p) => strings.includes(p.Name) ?? [])

      const potentialCartItems = [...objs, ...stringToObjects]

      const readyForCart = potentialCartItems.filter(nullCheck).filter(qtyCheck).filter(parentExists)

      //remove those items not ready for cart
      potentialCartItems
        .filter(nullCheck)
        .filter((f) => !readyForCart.find((ready) => ready.Name === f.Name))
        .forEach((r) => {
          if (r !== null && choices[r.Name].qty) {
            choices[r.Name].qty = 0
            choices[r.Name].calculatedPrice = 0
          }
        })
      $store.commit('setInternetUpgrades', [...objs, ...stringToObjects])
      renderMe.value = renderMe.value + 1
    }

    const equipment = computed(() => props.product?.Equipment ?? [])

    const productGroups = computed(() => {
      const equip = equipment.value.map((e) => {
        e.Rank ??= Number.MAX_SAFE_INTEGER // lowest rank
        return e
      })
      const dontsort = min(equip.map((x) => x.Rank)) === max(equip.map((x) => x.Rank))

      //todo: revisit below
      const grouped = equipment.value ? groupBy(equipment.value, 'Group') : []
      const test = Object.keys(grouped).map((k) => ({
        group: k,
        products: dontsort ? grouped[k] : grouped[k].sort((a, b) => a.Rank - b.Rank),
        meanrank: mean(grouped[k].map((x) => x.Rank)) // KWC for now just use mean value for rank of the group
      }))

      return dontsort ? test : test.sort((a, b) => a.meanrank - b.meanrank)
    })

    const initializeModel = () => {
      Object.keys(choices).forEach((k) => {
        delete choices[k]
      })
      const dd = $store.getters.getInternetUpgrades

      dd.forEach((d) => {
        //radio buttons use groups
        if (d.Min === 1 && d.Max === 1) {
          choices[d.Group] = d.Name
        }
        //checkbox dont use groups
        if (d.Min === 0 && d.Max === 1) {
          choices[d.Name] = d.Name
        }
        //product picker uses objects
        if (d.Max > 1) {
          choices[d.Name] = { ...d, calculatedPrice: addCalculatedPrice(d) }
        }
      })
      renderMe.value = renderMe.value + 1
    }

    onMounted(() => {
      initializeModel()
      tvRequired.value.splice(0, 10)
    })

    // this isn't needed, since the entire componenet gets remounted on package change
    bus.$on('packageChanged', (data) => {
      initializeModel()
      tvRequired.value.splice(0, 10)
    })

    const tvRequired = ref([] as any[])
    const isTvRequired = (name) => {
      return tvRequired.value.includes(name)
    }

    bus.$on('tvProductChanged', (tvProduct) => {
      if (tvProduct['Groups']) {
        tvRequired.value.splice(0, 10)
        const required = flatten(tvProduct['Groups'].map((g) => g['Required Equipment']).filter((g) => g))

        required.forEach((r: any) => {
          tvRequired.value.push(r)
          choices[r] = r
          equipmentChanged(r)
        })
        initializeModel()
      }
    })

    const notAllExcluded = (products) => products.some((product) => !product.excluded && !product.Parent) //check if for a given group's products, at least one should display
    const shouldShowProduct = (p) => !p.Parent && !p.excluded

    return {
      itemDisplayName,
      equipment,
      equipmentChanged,
      getGroupTitle,
      getMarketingInfo,
      choices,
      dialog,
      checkbox,
      productPicker,
      findChildren,
      isVisible,
      isVisibleRadio,
      mdiInformation,
      getProductSubtitle,
      renderMe,
      productGroups,
      radioGroup,
      isTvRequired,
      shouldShowProduct,
      getItemPrice,
      notAllExcluded,
      internetNoOptionsMessage,
      noItemsToShow,
      internetHeaderImage,
      internetHeaderText,
      internetHeaderBackgroundColor,
      isProductPicker,
      getGroupSubtitle,
      getCatalogItemClassName,
      coreCurrency,
      dividersInCards,
      uiMacroParser,
      shopper,
      additionalServicesInsideInternetCard
    }
  }
})
</script>

<style>
#internet .v-list-item {
  margin: 0px;
  padding: 0px;
  min-height: 6px;
}

.v-list-group__header {
  height: 0px;
}

#internet .v-list-group__header {
  min-height: 0px;
}
#internet.v-list-group py-0 v-list-group--active v-list-group--disabled primary--text {
  padding: 0px;
}
.upgrade-radio-subtitle {
  padding-left: 40px;
}
</style>
