<template>
  <v-container fluid :class="currentStep === 1 ? 'fill-height' : ''" class="pa-0" id="order-container">
    <v-img height="100%" :src="backgroundImage" :id="backgroundImageId">
      <v-row justify="center" no-gutters style="height: 100%">
        <!-- <ServiceabilityQuestions v-if="prompt && !showSpinner.show" /> -->
        <v-stepper
          v-show="!showSpinner"
          class="elevation-0"
          id="v-stepper-container"
          v-model="currentStep"
          :alt-labels="true"
          :style="currentStep === 1 ? 'width: 100%' : ''"
        >
          <v-container id="stepper-container" class="text-center pt-1 pb-0 full-width">
            <v-stepper-header
              id="breadcrumb-padding"
              class="elevation-0 pt-2 pb-0"
              v-show="$vuetify.breakpoint.mdAndUp && showStepper"
            >
              <div v-if="uiConfig.logoInSteppers && currentStep === 1" class="stepper-logo">
                <a :href="logoLink" target="_blank">
                  <img :src="headerLogo" />
                </a>
              </div>

              <div v-for="(step, index) in steps" :key="index">
                <v-stepper-step
                  v-if="!step.hide"
                  :id="breadCrumbId(index)"
                  :class="{
                    'current-step': currentStep - 1 === index,
                    'default-step': currentStep - 1 !== index
                  }"
                  class="px-0 py-2 subtitle-1"
                  :step="index + 1"
                  :complete="currentStep > index + 1"
                >
                  <span
                    :name="debugName(`config.step.[${index}].label`)"
                    :id="'step-label-' + currentStep"
                    class="subtitle-1"
                    :class="{
                      'current-step-label': currentStep - 1 === index,
                      'default-step-label': currentStep - 1 !== index
                    }"
                  >
                    {{ step.label }}</span
                  >
                </v-stepper-step>
              </div>
            </v-stepper-header>
          </v-container>
          <v-stepper-items :id="'stepperItems-' + currentStep" class="full-width" style="overflow: visible">
            <v-stepper-content v-for="(step, index) in steps" :key="index" class="pt-0 pb-1 px-1" :step="index + 1">
              <Serviceability
                v-if="step.component === 'Serviceability'"
                v-on:goForward="forward"
                v-on:goBack="back"
                v-on:submitOrder="submitOrder"
              />
              <Offers
                v-if="step.component === 'Offers'"
                v-on:goForward="forward"
                v-on:goBack="back"
                v-on:submitOrder="submitOrder"
              />
              <Customize
                v-if="step.component === 'Customize'"
                v-on:goForward="forward"
                v-on:goBack="back"
                v-on:submitOrder="submitOrder"
              />
              <Account
                v-if="step.component === 'Account'"
                v-on:goForward="forward"
                v-on:goBack="back"
                v-on:submitOrder="submitOrder"
              />
              <Schedule
                v-if="step.component === 'Schedule'"
                v-on:goForward="forward"
                v-on:goBack="back"
                v-on:submitOrder="submitOrder"
              />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-row>
    </v-img>
  </v-container>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeUnmount, onMounted, watch } from '@vue/composition-api'
import Serviceability from '@/components/serviceability/Serviceability.vue'
import Customize from '@/components/order/Customize.vue'
import Offers from '@/components/order/Offers.vue'
import Account from '@/components/order/account/Account.vue'
import Schedule from '@/components/order/scheduling/Schedule.vue'
import Cart from '@/components/order/cart/Cart.vue'
import ServiceabilityQuestions from '@/components/serviceability/ServiceabilityQuestions.vue'
import useOrder from '@/components/order/useOrder'
import { API_CONTENT_ROOT } from '@/store/types'
import store from '@/store'
//import router from '@/router'
import { bus } from '@/main'
import { getConfigItem } from '../shared/getConfigItem'
import { ConfigKeys } from '@adg/catalog/src/modules/Catalog'
import { IShopper } from '@adg/catalog/src/modules/Shopper'
import { Place } from '@adg/catalog/src/common/addressUtil'
import { usePiniaShopper } from '@/store/pinia/piniaShopper'
import { UrlParams, getFirstUrlParam } from '@adg/catalog/src/common/utils'
import { logger } from '@/utils/RemoteLogger'
import { Name } from '@adg/catalog/src/common/UIConfig'
import ga4 from '@/gtm/ga4'
import ecom from '@/gtm/ecom'
import { debugName } from '@/utils/StoreHelpers'

export default defineComponent({
  /*
  beforeRouteEnter(to, from, next) {
    const stepTo = Number.parseInt(to.path.substr(to.path.lastIndexOf('/') + 1))
    const stepFrom = Number.parseInt(
      from.path.substr(to.path.lastIndexOf('/') + 1)
    )
    this.currentStep = Number.parseInt(stepTo)

    next()
  },
 */
  beforeRouteUpdate(to, from, next) {
    const stepTo = Number.parseInt(to.path.substr(to.path.lastIndexOf('/') + 1))
    const stepFrom = Number.parseInt(from.path.substr(to.path.lastIndexOf('/') + 1))
    this.currentStep = stepTo
    if (stepFrom < stepTo && to.path != '/order/5') {
      this.updateShopper('Transitioning from step ' + stepFrom + ' to step ' + stepTo)
    }
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  },

  components: {
    Serviceability,
    ServiceabilityQuestions,
    Offers,
    Customize,
    Account,
    Schedule,
    Cart
  },
  setup(props, context) {
    const {
      //root: { $store, $router }
      root: { $router, $vuetify } // try refactor out $store  need to also refactor out router .. dont know how
    } = context

    // const shopper = computed((): IShopper => store.getters.getShopper)

    const showSpinner = computed(() => store.getters.getSpinners.length)
    // const prompt = computed(() => {
    //   if (addressVerified.value === undefined) return false
    //   return currentStep.value > 1 && !addressVerified.value
    // })

    const uiConfig = computed(() => store.getters.getUIConfig)

    const headerLogo = computed(() => getConfigItem(ConfigKeys.headerLogo) ?? '/api/content/logo.png')
    const logoLink = computed(() => getConfigItem(ConfigKeys.logoLink))
    const logoInSteppers = computed(() => getConfigItem(ConfigKeys.logoInSteppers) ?? false)

    const hideMobileBackgroundImage = computed(() => getConfigItem(ConfigKeys.hideMobileBackgroundImage))

    const breadCrumbId = (index: number) => {
      let id
      if (currentStep.value - 1 === index) {
        id = 'current-breadcrumb'
      } else if (currentStep.value - 1 > index) {
        id = 'past-breadcrumb'
      } else if (currentStep.value - 1 < index) {
        id = 'future-breadcrumb'
      }

      return id
    }

    const showStepper = computed(() => {
      let hideCurrentStep = false
      if (currentStep.value === 1) {
        hideCurrentStep = uiConfig.value.buyFlow?.find((c: any) => c.name === 'serviceability')?.hideStepper
      } else {
        hideCurrentStep = uiConfig.value.buyFlow?.find((c: any) => c.name === 'common')?.hideStepper
      }
      return !showSpinner.value && !hideCurrentStep
    })

    const { validation, currentStep, addressVerified, fetchOffers, submitOrder, steps, goToStep, updateShopper, catalogSpinner } =
      useOrder(store, $router)

    const backgroundImage = computed(() => {
      if (currentStep.value === 1) {
        return uiConfig.value.buyFlow
          ? `/api/content/${uiConfig.value.buyFlow.find((c: any) => c.name === Name.Serviceability)?.backgroundImage}`
          : ''
      }
      if (currentStep.value === 2 && uiConfig.value.useAlternateBackgroundImage) {
        return uiConfig.value.buyFlow
          ? `/api/content/${uiConfig.value.buyFlow.find((c: any) => c.name === Name.Offers)?.backgroundImage}`
          : ''
      }
      if (currentStep.value === 3 && uiConfig.value.useAlternateBackgroundImage) {
        return uiConfig.value.buyFlow
          ? `/api/content/${uiConfig.value.buyFlow.find((c: any) => c.name === Name.Customize)?.backgroundImage}`
          : ''
      }
      if (currentStep.value === 4 && uiConfig.value.useAlternateBackgroundImage) {
        return uiConfig.value.buyFlow
          ? `/api/content/${
              uiConfig.value.buyFlow.find((c: any) => c.name === Name.Account || c.name === Name.AccountInfo)?.backgroundImage
            }`
          : ''
      }
      if (currentStep.value === 5 && uiConfig.value.useAlternateBackgroundImage) {
        return uiConfig.value.buyFlow
          ? `/api/content/${uiConfig.value.buyFlow.find((c: any) => c.name === Name.Installation)?.backgroundImage}`
          : ''
      } else {
        const imageName = uiConfig?.value?.buyFlow?.find((c: any) => c.name === Name.Common).backgroundImage
      }

      let image = uiConfig.value?.buyFlow?.find((c: any) =>
        currentStep.value === 1 ? c.name === 'serviceability' : c.name === 'common'
      )?.backgroundImage

      if ($vuetify.breakpoint.smAndDown && hideMobileBackgroundImage && currentStep.value > 1) {
        return undefined
      }
      return image ? `${API_CONTENT_ROOT}/${image}` : undefined
    })

    bus.$on('goToError', () => $router.push('/error'))
    bus.$on('stepForward', () => forward())
    bus.$on('resetApp', () => {
      store.commit('resetOrder')
      goToCustomStep(1)
    })
    bus.$on('goToOffers', () => goToCustomStep(2))
    bus.$on('stepBack', () => back())
    bus.$on('submitOrder', () => {
      return submitOrder()
    })
    bus.$on('goToScheduling', () => {
      return goToCustomStep(5)
    })
    bus.$on('goToConfirmation', () => $router.push('/confirm'))
    bus.$on('goToPreSale', () => $router.push('/preSale'))

    const forward = () => {
      let newStep = currentStep.value + 1
      //Shouldn't currentstep be updated after the newStep is potentially increased below?
      //or is this acutally failing successfully because we are removing that breadcrumb?
      currentStep.value = newStep
      if (newStep === 2) {
        const address = store.getters.getShopper.formattedAddress
        fetchOffers(address)
      } else if (newStep === 3) {
        if (uiConfig.value.skipCustomization) {
          ecom.pushPageView('/customizeOptions')
          ga4.pushPageView('/customizeOptions')
          //alter line below when breadcrumb is removed in future ticket
          newStep += 1
        } else if (store.getters.getShopper.cart?.package?.SkipCustomization === 1) {
          ecom.pushPageView('/customizeOptions')
          ga4.pushPageView('/customizeOptions')
          newStep += 1
        }
        goToStep(newStep)
      } else {
        goToStep(newStep)
      }
      window.scrollTo(0, 0)
    }
    const goToCustomStep = (newStep: number) => {
      currentStep.value = newStep
      if (newStep === 2) {
        const address = store.getters.getShopper.formattedAddress ?? store.getters.getShopper.inputAddress
        fetchOffers(address)
      } else {
        goToStep(newStep)
      }
      window.scrollTo(0, 0)
    }

    const back = () => {
      const newStep = currentStep.value - 1
      currentStep.value = newStep
      goToStep(newStep)
    }

    const backgroundImageId = computed(() => {
      switch (currentStep.value) {
        case 1:
          return 'address-background'
          break
        case 2:
          return 'buyflow-background'
          break
        case 3:
          return 'buyflow-background'
          break
        case 4:
          return 'buyflow-background'
          break
        case 5:
          return 'buyflow-background'
          break
        default:
          return ''
          break
      }
    })

    const showMobileCart = computed(() => store.getters.getMobileCart)

    watch(uiConfig, async (newVal, oldVal) => {
      if (newVal) {
        const urlParamsObj: UrlParams = store.getters.getUrlParams

        if (getFirstUrlParam(urlParamsObj.token)) {
          store.commit('addSpinner', { id: 'auth', messages: ['Authorizing token'], rank: 10 })
          try {
            const authorized = await store.dispatch('authorizeToken', urlParamsObj.token)
            if (!authorized) {
              logger.warn('token authorization failed')
              throw new Error('token authorization failed')
            }
          } catch (e) {
            //reload the app to clear the token and all params
            window.location.href = `${window.location.origin}`
          } finally {
            store.commit('removeSpinner', 'auth')
          }
        }

        if (getFirstUrlParam(urlParamsObj.id) && !getFirstUrlParam(urlParamsObj.ro)) {
          store.dispatch('getPreviousShopper', { id: urlParamsObj.id, type: 'exId' }).then(() => {})
        }
        if (getFirstUrlParam(urlParamsObj.sid) && !getFirstUrlParam(urlParamsObj.ro)) {
          store.dispatch('getPreviousShopper', { id: urlParamsObj.sid, type: 'sId' }).then(() => {})
        }

        if (getFirstUrlParam(urlParamsObj.type) === 's') {
          store.commit('setStudent', true)
          usePiniaShopper().shopper.customInfo.isStudent = true
        }

        const previousAddress = computed(() => store.getters.getFormattedAddress)
        if (getFirstUrlParam(urlParamsObj.address) && !previousAddress.value) {
          store.commit('addSpinner', catalogSpinner)
          const place: Place = { full: getFirstUrlParam(urlParamsObj.address) }
          store.commit('updateShopperAddress', place)
          goToCustomStep(2)
        }
      }
    })

    onBeforeUnmount(() => {
      bus.$off('stepForward')
      bus.$off('stepBack')
      bus.$off('submitOrder')
    })

    return {
      currentStep,
      steps,
      forward,
      back,
      submitOrder,
      validation,
      updateShopper,
      showMobileCart,
      addressVerified,
      prompt,
      showSpinner,
      uiConfig,
      backgroundImage,
      showStepper,
      backgroundImageId,
      breadCrumbId,
      headerLogo,
      logoInSteppers,
      logoLink,
      debugName
    }
  }
})
</script>

<style>
span.v-stepper__step__step {
  font-size: 1em;
  height: 30px;
  width: 30px;
}

.theme--light.v-stepper {
  background: transparent !important;
  background-color: transparent !important;
}

.full-width {
  width: 100vw;
}

#past-breadcrumb .v-stepper__label {
  width: max-content;
}
#future-breadcrumb .v-stepper__label {
  width: max-content;
}
</style>
