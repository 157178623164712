
<div class="sub-header-container">
  <span v-if="largeText" class="sub-header-large-text">
    <div>
      {{ largeText }}
      <v-btn>
        <v-icon v-if="icon && punctuatedAddress">{{ icon }}</v-icon
        >{{ punctuatedAddress }}</v-btn
      >
    </div>
  </span>
  <div v-if="smallText" class="subheader-small-text">{{ smallText }}</div>
</div>
